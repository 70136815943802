const yup = require('yup')
const { pluck } = require('ramda')
const fontSizes = require('../../display-font-sizes')

const displayOptionsSchema = () => ({
  fontScale: yup
    .number()
    .oneOf([...pluck('value', fontSizes), null])
    .nullable()
    .default(null),
  showDisplayInfo: yup.string().oneOf(['inherit', 'true', 'false']).default('inherit'),
})

module.exports = displayOptionsSchema
