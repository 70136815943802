const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  profile: yup.mixed(),
  locationVehicle: yup.mixed(),
  drivingMinutes: yup.number().default(0),
  idleMinutes: yup.number().default(0),
  currentRiders: yup.number().min(0).default(0),
  totalRiders: yup.number().min(0).default(0),
  eventCount: yup.number().min(0).default(0),
  isAcceptingRequests: yup.boolean().default(true),
  startedAt: yup.date(),
  endedAt: yup.date(),
})

const locationVehicleShiftSchemaPartial = yup.object().shape(shape)
const locationVehicleShiftSchema = locationVehicleShiftSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { locationVehicleShiftSchema, locationVehicleShiftSchemaPartial }
