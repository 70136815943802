const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const { uploadSchema } = require('./cloudinary-schema')
const displayLocation = require('./fields/display-location')
const scheduleDate = require('./fields/shedule-date')
const { dailySchedules } = require('./fields/daily-schedules')
const scheduleDateTime = require('./fields/shedule-datetime')

const shape = labelify({
  title: yup.string().default('').max(150).meta({ maxLength: 150 }),
  subtitle: yup.string().default('').max(240).meta({ maxLength: 240 }),
  mobileDescription: yup.string().default('').max(750).meta({ maxLength: 750 }),
  active: yup.boolean().default(true),
  duration: yup.number().default(30),
  contentSource: yup.string().oneOf(['upload', 'embed', 'create', 'library']).default('upload'),
  schedule: yup.string().oneOf(['immediate', 'scheduled', 'event']).default('immediate'),
  scheduleAt: scheduleDate(),
  scheduleEndAt: scheduleDate(),
  dailySchedules: dailySchedules(),
  playInBackground: yup.boolean().default(false),
  appearance: yup
    .string()
    .when('contentSource', { is: 'embed', then: (s) => s.oneOf(['normal', 'maximized', 'fullscreen']), otherwise: (s) => s.oneOf(['normal', 'maximized']) })
    .default('normal'),
  displayLocation: displayLocation(),
  filterDevices: yup.string().oneOf(['all', 'selected', 'none']).default('all'),
  devices: yup.array().of(yup.string()).default([]),
  sortOrder: yup.number().min(0),
  updateDownloadableDesign: yup.boolean().default(false),
  duplicateOf: yup.mixed(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const infoScreenPosterSchemaPartial = yup.object().shape(shape)
const infoScreenPosterSchema = infoScreenPosterSchemaPartial.shape(requiredFields(['title', 'appearance'])(shape)).shape({
  content: uploadSchema.nullable(),
  libraryItem: yup.mixed(),
  libraryDailyContent: yup.mixed(),
  playInBackground: yup
    .boolean()
    .when(['contentSource', 'libraryItem', 'libraryDailyContent', 'displayLocation'], {
      is: (contentSource, libraryItem, libraryDailyContent, displayLocation) =>
        contentSource !== 'library' || (!libraryItem && !libraryDailyContent) || displayLocation === 'mobile',
      then: (s) => s.transform(() => false),
      otherwise: (s) => s,
    })
    .default(false),
  event: yup.mixed(),
  embedURL: yup
    .string()
    .matches(/^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/\S*)?$/, { message: 'Must be a valid url.', excludeEmptyString: true })
    .ensure()
    .label('Embed URL')
    .when('contentSource', { is: 'embed', then: (s) => s.required() }),
  editableLayout: yup.mixed(),
  design: uploadSchema.nullable(),
})

const mediaFormSchema = infoScreenPosterSchema.shape({
  scheduleAt: scheduleDateTime(),
  scheduleEndAt: scheduleDateTime(),
})

module.exports = { infoScreenPosterSchema, infoScreenPosterSchemaPartial, mediaFormSchema }
