import format from '../../../shared/format'

const isDev = !import.meta.env.DEV
const { Intercom: ic, intercomSettings: settings } = window

const intercom = (user) => {
  if (!isDev && ic) {
    const { email, name, registeredAt, intercomHash, companies = [] } = user
    const createdUnixTimestamp = Math.floor(new Date(registeredAt).valueOf() / 1000)
    ic('update', {
      ...settings,
      email,
      name: format.name(name),
      created_at: createdUnixTimestamp,
      user_hash: intercomHash,
      companies,
    })
  }
}

export default intercom
