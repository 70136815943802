const yup = require('yup')
const { labelify, requiredFields } = require('./helpers')
const { uploadSchema } = require('./cloudinary-schema')
const { seasonSchema } = require('./season-schema')
const timeField = require('./fields/time-field')
const { fromPairs } = require('ramda')
const template = require('lodash.template')
const displayOptionsSchema = require('./fields/display-options')

const testMonthlyPercentDistribution = async (eventLabels = []) => {
  if (!eventLabels.length) return true

  const monthlyEventsDistribution = eventLabels.reduce(
    (acc, { category, monthTargetPercent }) => {
      acc[category]['percent'] += Number(monthTargetPercent) || 0
      acc[category]['count'] += 1
      return acc
    },
    { Event: { percent: 0, count: 0 }, Menu: { percent: 0, count: 0 } }
  )

  const { Event: event = {}, Menu: menu = {} } = monthlyEventsDistribution

  const isEventFullyDistributed = event?.count > 0 ? event?.percent === 100 : true
  const isMenuFullyDistributed = menu?.count > 0 ? menu?.percent === 100 : true

  return isEventFullyDistributed && isMenuFullyDistributed
}

const nameTemplateFieldNames = ['first_name', 'last_name', 'first_initial', 'last_initial']
const nameTemplateFields = nameTemplateFieldNames.map((name) => `{{${name}}}`).join(', ')
const nameTemplateTestString = '%%not_user_value&&'
const nameTemplateTestValues = fromPairs(nameTemplateFieldNames.map((name) => [name, nameTemplateTestString]))

const yupTemplateTest = {
  name: 'is-template',
  skipAbsent: false,
  test(v) {
    const context = this
    const interpolate = /{{([\s\S]+?)}}/g
    try {
      const name = template(v, { interpolate })(nameTemplateTestValues)
      if (!name || !name.includes(nameTemplateTestString)) {
        return context.createError({ message: `Template must contain at least one of: ${nameTemplateFields}` })
      }
    } catch (error) {
      return context.createError({ message: `Problem in template: ${error.message}` })
    }
    return true
  },
}

const shape = labelify({
  mailArrivedAt: yup.date().nullable(),
  parent: yup.mixed(),
  isDailyContentEnabled: yup.boolean().default(false),
})

const eventLabelsSchema = yup.object().shape({
  _id: yup.string(),
  category: yup.string().oneOf(['Event', 'Menu']).default('Event'),
  label: yup.string().default(''),
  color: yup.string().ensure().default('#C1E2EC'),
  minEventsPerWeek: yup.number().positive().label('Minimum events per week').default(5),
  monthTargetPercent: yup.number().min(0).max(100).label('Target percent').default(100),
  start: timeField(),
  end: timeField(),
})

const settingsSchema = yup.object().shape({
  logo: uploadSchema,
  introLogo: uploadSchema,
  address: yup
    .object({
      street1: yup.string(),
      street2: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zip: yup.string(),
    })
    .optional(),
  mobileIntro: yup
    .object({
      contact: yup.string(),
    })
    .optional(),
  mobileRoleRequestToken: yup.string().nullable(),
  publishedNameTemplate: yup.string().default('{{first_name}} {{last_name}}'),
  birthdayUpdates: yup
    .object()
    .shape({
      automaticallyCreate: yup.boolean(),
      primaryTemplate: yup.string(),
      detailsTemplate: yup.string(),
    })
    .optional(),
  socialMedia: yup
    .object({
      instagram: yup.string(),
      twitter: yup.string(),
      facebook: yup.string(),
      youTube: yup.string(),
      tikTok: yup.string(),
      vimeo: yup.string(),
    })
    .optional(),
  eventSettings: yup.object().shape({
    locationCode: yup.boolean().default(false),
    eventLabels: yup.array().of(eventLabelsSchema),
    labels: yup.array().of(yup.string()).default([]),
    mealTimes: yup.array().of(yup.string()).default([]),
    menuTemplateType: yup.mixed(),
  }),
  ...displayOptionsSchema(),
})

const contactFormSchema = yup.object().shape({
  _id: yup.string(),
  mobileIntro: yup.object().shape({
    contact: yup.string().max(400),
  }),
  socialMedia: yup.object().shape({
    instagram: yup.string(),
    twitter: yup.string(),
    facebook: yup.string(),
    youTube: yup.string(),
    tikTok: yup.string(),
    vimeo: yup.string(),
  }),
})

const settingsFormSchema = yup.object().shape({
  _id: yup.string(),
  logo: uploadSchema,
  introLogo: uploadSchema,
  seasons: yup.array().of(seasonSchema).default([]),
  address: yup.object().shape(
    requiredFields(['street1', 'city', 'state', 'zip'], {
      street1: yup.string().ensure(),
      street2: yup.string().ensure(),
      city: yup.string().ensure(),
      state: yup.string().ensure(),
      zip: yup.string().ensure(),
    })
  ),
  publishedNameTemplate: yup.string().max(100).meta({ maxLength: 100 }).default('{{first_name}} {{last_name}}').test(yupTemplateTest),
  birthdayUpdates: yup.object().shape({
    automaticallyCreate: yup.boolean().default(false),
    primaryTemplate: yup.string().max(100).meta({ maxLength: 100 }).default('Happy Birthday {{first_name}} {{last_name}}!').test(yupTemplateTest),
    detailsTemplate: yup.string().default('🎉 Happy Birthday {{first_name}} {{last_name}}! 🎉'),
  }),
  eventSettings: yup.object().shape({
    locationCode: yup.boolean().default(false),
    eventLabels: yup.array().of(eventLabelsSchema).test('is-total-distribution-100', 'Invalid monthly distribution', testMonthlyPercentDistribution),
    labels: yup.array().of(yup.string()).default([]),
    mealTimes: yup.array().of(yup.string()).default([]),
  }),
})

const resortSchema = yup.object().shape(shape).concat(settingsSchema)

const responseSchema = resortSchema.shape({
  latitude: yup.number(),
  longitude: yup.number(),
  timezone: yup.string(),
  hasDescendants: yup.boolean().default(false),
})

module.exports = { resortSchema, settingsFormSchema, contactFormSchema, responseSchema, eventLabelsSchema, nameTemplateFields }
