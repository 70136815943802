import { connect } from '@cerebral/react'
import { Box, Button, Card, CardContent, CardMedia, Grid, Link, List, ListItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { props, sequences, state } from 'cerebral'
import cc from 'classcat'
import PropTypes from 'prop-types'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { titleize } from 'underscore.string'
import appModules from '../../lib/util/app-modules'
import { reactionLifecycle } from '../../lib/util/cerebral'
import { exists, url } from '../../lib/util/cloudinary'
import { routePaths } from '../../modules/route'
import DropDown from '../controls/DropDown'
import ListPagination from '../controls/ListPagination'
import ActionScreen from '../elements/ActionScreen'
import Activity from '../elements/Activity'
import LoadingScreen from '../elements/LoadingScreen'
import FilterBy from './FilterBy'
import Search from './Search'
import { DragIndicator } from '@mui/icons-material'
import { useCallback } from 'react'
import ActionHeader from '../elements/ActionHeader'
import DropDownTitle from '../elements/DropDownTitle'

const useStyles = makeStyles((theme) => ({
  Card: {
    display: 'flex',
    width: '100%',
    alignItems: 'stretch',
  },
  CardInactive: {
    opacity: 0.7,
  },
  ContentMain: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  ContentExtra: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  CardMedia: {
    width: 231,
    height: 200,
    margin: theme.spacing(2),
  },
  MediaPlaceholder: {
    flex: '0 0 auto',
    width: 200,
    minHeight: 150,
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& path': {
      fill: 'currentColor',
    },
  },
}))

export const CardListItem = ({
  item,
  title,
  href,
  subtitle,
  body,
  extra,
  active = true,
  activity = [],
  actions = [],
  status,
  media,
  mediaPlaceholder,
  dragSort = false,
}) => {
  const classes = useStyles()

  return (
    <Card classes={{ root: cc([classes.Card, !active && classes.CardInactive]) }}>
      <CardContent classes={{ root: classes.ContentMain }}>
        <Box>
          {title && (
            <Typography gutterBottom variant="h4">
              {href ? <Link href={href}>{title}</Link> : title}
            </Typography>
          )}
          {subtitle && (
            <Typography gutterBottom variant="h6">
              {subtitle}
            </Typography>
          )}
          {body && (
            <Typography component="div" paragraph={false} variant="body1">
              {body}
            </Typography>
          )}
        </Box>
        <Box flex={1} />
        {activity.length > 0 && (
          <Box>
            {activity.map((key) => (
              <Activity key={key} action={titleize(key)} time={item[`${key}At`]} user={item[`${key}By`]} />
            ))}
          </Box>
        )}

        <Box display="flex" alignItems="center" mt={1}>
          {actions.length > 0 && (
            <Box ml={-0.5} my={-1}>
              <DropDown>{actions}</DropDown>
            </Box>
          )}
          <Box ml={1}>{status && <Box>{status}</Box>}</Box>
        </Box>
      </CardContent>

      {exists(media) ? (
        <CardMedia className={classes.CardMedia} image={url({ width: 231, height: 200, crop: 'pad', background: 'white', format: 'jpg' }, media)} />
      ) : mediaPlaceholder ? (
        <Box className={classes.MediaPlaceholder}>{mediaPlaceholder}</Box>
      ) : null}

      {extra && <Box p={1}>{extra}</Box>}

      {dragSort && (
        <Box p={2}>
          <DragIndicator />
        </Box>
      )}
    </Card>
  )
}

CardListItem.propTypes = {
  item: PropTypes.object.isRequired,
  title: PropTypes.node,
  href: PropTypes.string,
  subtitle: PropTypes.node,
  body: PropTypes.node,
  extra: PropTypes.node,
  active: PropTypes.bool,
  activity: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.arrayOf(PropTypes.node),
  status: PropTypes.node,
  media: PropTypes.any,
  mediaPlaceholder: PropTypes.node,
  dragSort: PropTypes.bool,
}

const ListDisplay = ({ ids, typeDisplay, Component, onDragSort, isNotDraggable }) => {
  const list = (
    <List>
      {ids.map((id, index) => {
        const dragSort = Boolean(onDragSort && (!isNotDraggable || !isNotDraggable(id)))

        const item = (
          <ListItem disableGutters key={id}>
            <Component type={typeDisplay} id={id} dragSort={dragSort} />
          </ListItem>
        )

        return dragSort ? (
          <Draggable key={id} draggableId={id} index={index}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                {item}
              </div>
            )}
          </Draggable>
        ) : (
          item
        )
      })}
    </List>
  )

  const onDragEnd = useCallback(
    ({ source, destination, draggableId }) => {
      if (onDragSort) {
        onDragSort({ id: draggableId, from: source.index, to: destination.index })
      }
    },
    [onDragSort]
  )

  return onDragSort ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="cardList">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {list}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    list
  )
}

ListDisplay.propTypes = {
  ids: PropTypes.array,
  typeDisplay: PropTypes.string,
  Component: PropTypes.any,
  onDragSort: PropTypes.func,
  isNotDraggable: PropTypes.func,
}

const CardList = connect(
  {
    find: sequences`${props`type`}s.findForList`,
    list: state`${props`type`}s.list`,
  },

  ({
    title,
    type,
    typeDisplay,
    route,
    createHref,
    category,
    find,
    list,
    itemComponent,
    intro,
    renderContent,
    emptyExtra,
    actionButtons,
    create = true,
    search,
    searchExtra,
    filter,
    reaction,
    onDragSort,
    isNotDraggable,
    style,
  }) => {
    reactionLifecycle(reaction, `routeChange`, { route: state`route` }, () => find())

    const { ids, loading = true } = list
    const routePath = routePaths[route || `to${titleize(type)}`]
    const appModule = appModules[type] || {}
    createHref = createHref || (routePath && routePath({ id: 'create' }))
    typeDisplay = typeDisplay || appModule.displayType || type
    const pluralType = appModule.plural || `${typeDisplay}s`
    const hasFilterOptions = filter?.options?.length > 0
    const isDropdownDisplayed = typeDisplay === 'event' || typeDisplay === 'meal'

    const body = (
      <>
        {loading ? (
          <LoadingScreen />
        ) : ids.length === 0 ? (
          <ActionScreen title={`There are no ${category || ''} ${pluralType}.`} color="muted">
            {createHref && (
              <Button component={Link} color="primary" href={createHref}>
                Add {typeDisplay}
              </Button>
            )}
            {emptyExtra}
          </ActionScreen>
        ) : (
          <ListDisplay ids={ids} typeDisplay={typeDisplay} Component={itemComponent} onDragSort={onDragSort} isNotDraggable={isNotDraggable} />
        )}
        <ListPagination list={list} find={find} />
      </>
    )

    return (
      <Box style={style} pb={6}>
        <ActionHeader title={title || appModule.moduleTitle} titleContent={isDropdownDisplayed ? <DropDownTitle title={title} /> : null}>
          {actionButtons}
          {create && createHref && (
            <Button component={Link} color="primary" href={createHref}>
              Add {typeDisplay}
            </Button>
          )}
        </ActionHeader>
        {Boolean(intro || search || hasFilterOptions || searchExtra) && (
          <Box mb={2} mt={3}>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{ flexGrow: 1 }}>
                {intro && (
                  <Box my={2} mr={2}>
                    {intro}
                  </Box>
                )}
              </Grid>
              {search && (
                <Grid item md={3}>
                  <Search placeholder={`Filter ${category ? category + ' ' : ''}${pluralType}`} />
                </Grid>
              )}
              {searchExtra && <Grid item>{searchExtra}</Grid>}
              {hasFilterOptions && (
                <Grid item md={2}>
                  <FilterBy {...filter} />
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        {typeof renderContent === 'function' ? renderContent(body) : body}
      </Box>
    )
  }
)

CardList.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  typeDisplay: PropTypes.string,
  route: PropTypes.string,
  itemComponent: PropTypes.func.isRequired,
  createHref: PropTypes.string,
  search: PropTypes.bool,
  searchExtra: PropTypes.node,
  actionButtons: PropTypes.node,
  onDragSort: PropTypes.func,
  isNotDraggable: PropTypes.func,
  style: PropTypes.object,
}

export default CardList
