import React from 'react'
import PropTypes from 'prop-types'
import cc from 'classcat'
import { CssBaseline, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from '@cerebral/react'
import { state } from 'cerebral'
import TopBar from './TopBar'
import Nav from './Nav'
import PageSpacing from './PageSpacing'
import ErrorBoundary from './ErrorBoundary'
import Feedback from './Feedback'
import CurrentSnackbars from '../elements/CurrentSnackbars'

const useStyles = makeStyles((theme) => ({
  '@global': {
    'html, body, #root': {
      height: '100%',
    },
  },
  root: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.background.paper,
    },
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: '100%',
    marginLeft: 0,
    // Transition matched to persistent nav drawer settings.
    transition: theme.transitions.create(['margin-left'], { duration: theme.transitions.duration.enteringScreen }),
  },
  wrapperWithTopBar: {
    paddingTop: 64,
  },
  wrapperWithNav: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 256,
      '@media print': {
        marginLeft: 0,
      },
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: '100%',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'stretch',
  },
}))

const Layout = connect(
  {
    navShown: state`route.navShown`,
    navOffscreen: state`route.navOffscreen`,
  },

  ({ navShown, navOffscreen, children }) => {
    const classes = useStyles()

    return (
      <div className={classes.root}>
        <CssBaseline />
        <ErrorBoundary errorContent={<Typography>Something unexpected has gone wrong. Try reloading the browser window.</Typography>}>
          <TopBar />
          <Nav />
          <CurrentSnackbars />
        </ErrorBoundary>
        <Feedback />
        <div className={cc([classes.wrapper, navShown && classes.wrapperWithTopBar, navShown && !navOffscreen && classes.wrapperWithNav])}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <PageSpacing>
                <ErrorBoundary>{children}</ErrorBoundary>
              </PageSpacing>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

Layout.propTypes = {
  children: PropTypes.any,
}

export default Layout
