const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().default(''),
  description: yup.string().default(''),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const libraryDailyContentSchemaPartial = yup.object().shape(shape)
const libraryDailyContentSchema = libraryDailyContentSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { libraryDailyContentSchema, libraryDailyContentSchemaPartial }
