const yup = require('yup')
const timeField = require('./fields/time-field')
const { requiredFields, labelify } = require('./helpers')
const { fromPairs, intersection, path, pathEq, prop, union } = require('ramda')
const { DateTime, Interval } = require('luxon')
const { uploadSchema } = require('./cloudinary-schema')

const routeRefSchema = yup.object().shape({
  _id: yup.mixed().nullable(),
  timing: yup.object().shape({
    schedule: yup.string().oneOf(['always', 'scheduled']).default('always'),
    start: timeField(),
    end: timeField(),
    rrule: yup.object().shape({
      freq: yup.number().transform(() => 2).default(2),
      interval: yup.number().default(1),
      byweekday: yup.array().default([0, 1, 2, 3, 4, 5, 6]),
    }),
  }),
  locationRoute: yup.mixed().required().transform(v => v || null).default('').label('Route'),
  active: yup.boolean().default(true),
})

const shape = labelify({
  // TODO: Probably remove this again - it's here so we can use it for generic channel publishing.
  _id: yup.mixed(),
  resort: yup.mixed(),
  name: yup.string().max(100).meta({ maxLength: 100 }).default(''),
  description: yup.string().max(400).meta({ maxLength: 400 }).default(''),
  vehicleType: yup.string().oneOf(['bus', 'truck', 'car', 'other', 'special']).default('bus'),
  image:uploadSchema,
  routes: yup.array().of(routeRefSchema).test({
    name: 'route-conflict',
    message: 'Active routes may not have overlapping schedules.',
    test: (v = []) => {
      const routesExcludingOnDemand = v.filter(({ locationRoute: { routeType } }) => routeType !== 'on-demand')
      const activeRoutes = routesExcludingOnDemand.filter(prop('active'))
      const alwaysRoutes = activeRoutes.filter(pathEq('always', ['timing', 'schedule']))

      // There can be no conflict for a single route.
      if (activeRoutes.length === 1) {
        return true
      }

      // There is a conflict there is more than one route, and at least one route is set to always.
      if (alwaysRoutes.length > 0 && activeRoutes.length > 1) {
        return false
      }

      let daysScheduled = []
      let daysConflicted = []
      for (const route of activeRoutes) {
        const overlap = intersection(daysScheduled, route.timing.rrule.byweekday)
        if (overlap.length > 0) {
          daysConflicted = union(daysConflicted, overlap)
        }
        daysScheduled = union(daysScheduled, route.timing.rrule.byweekday)
      }

      // If no routes are scheduled on the same days, no need to compare times.
      if (daysConflicted.length === 0) {
        return true
      }

      const routesWithTimes = activeRoutes.map((route) => {
        let { timing: { start, end, rrule: { byweekday } } } = route
        start = start ? DateTime.fromFormat(start, 'h:mma') : DateTime.local().startOf('day')
        end = end ? DateTime.fromFormat(end, 'h:mma') : DateTime.local().endOf('day')
        const interval = Interval.fromDateTimes(start, end)
        const days = fromPairs(byweekday.map((day) => [day, true]))
        return { ...route, interval, days }
      })

      // If times overlap during any days which have conflicts, there is a conflict.
      for (const dayIndex of daysConflicted) {
        const dayRoutes = routesWithTimes.filter(path(['days', dayIndex]))
        const dayIntervals = []
        for (const route of dayRoutes) {
          const overlapping = dayIntervals.find((interval) => interval.overlaps(route.interval))
          if (overlapping) {
            return false
          }
          dayIntervals.push(route.interval)
        }
      }

      return true
    },
  }),
  locationDevice: yup.mixed(),
  active: yup.boolean().default(true),
  lastLocation: yup.object().shape({
    latitude: yup.number(),
    longitude: yup.number(),
    mileage: yup.number(),
    speed:yup.number(),
    updatedAt: yup.date(),
  }),
  maintenanceRequired: yup.boolean().default(false),
  mapColor: yup.string().ensure(),
  capacity:yup.number().min(0).nullable(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const locationVehicleSchemaPartial = yup.object().shape(shape)
const locationVehicleSchema = locationVehicleSchemaPartial.shape(requiredFields(['name', 'vehicleType', 'locationDevice'])(shape))

const locationVehicleResponseSchema = locationVehicleSchema.shape({
  lastLocation: yup.object().shape({
    latitude: yup.number().nullable(),
    longitude: yup.number().nullable(),
    mileage: yup.number().nullable(),
    speed:yup.number().nullable(),
    updatedAt: yup.date().nullable(),
  }),
})

const locationVehicleFormSchema = locationVehicleSchema.shape({
  locationDevice: yup.string().ensure().label('GPS Device'),
  capacity:yup.number().min(0).default(10),
})

module.exports = { locationVehicleSchema, locationVehicleResponseSchema, locationVehicleFormSchema, locationVehicleSchemaPartial, routeRefSchema }
