const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const { uploadSchema } = require('./cloudinary-schema')
const displayOptionsSchema = require('./fields/display-options')

const shape = labelify({
  name: yup.string().default(''),
  slug: yup.string().default(''),
  overrideScreens: yup.array().of(yup.mixed()).transform((v) => v.map((s) => s._id || s)).default([]),
  logo: uploadSchema,
  displayClockDate: yup.boolean().default(false),
  ...displayOptionsSchema(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
})

const kioskSchemaPartial = yup.object().shape(shape)
const kioskSchema = kioskSchemaPartial.shape(requiredFields(['name'])(shape))

const kioskFormSchema = kioskSchema.shape({
  // Ensure unique key to prevent React errors.
  overrideScreens: yup.array().of(yup.mixed()).transform((v) => {
    const existing = new Map()
    return v.map((screen) => {
      const _id = screen._id || screen
      const count = existing.get(_id) || 0
      const key = count ? `${_id}:${count}` : _id
      existing.set(_id, count + 1)
      return { _id, key }
    })
  }).default([]),
  logo: uploadSchema,
})

module.exports = { kioskSchema, kioskSchemaPartial, kioskFormSchema }
